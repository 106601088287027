
import { Dialog, Toast } from 'vant'
import { Options, Vue } from 'vue-class-component'
import api from '@/api/index'
@Options({
  components: {}
})
export default class Home extends Vue {
  search: any = {};
  showPicker = false;
  columns = ['中联银融资担保', '中联银工程担保'];
  showBH = false;

  baohan: any = {}

  codeloading = false;
  codeTime = 0;

  getCodeTime () {
    if (this.codeloading) {
      return '获取中'
    }
    return this.codeTime
      ? (this.codeTime < 10 ? '0' : '') + this.codeTime + '秒'
      : '获取验证码'
  }

  checkPhone (phone: string) {
    if (!phone) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入手机号码'
      })
      return false
    }
    if (phone.length !== 11) {
      Dialog.alert({
        title: '温馨提示',
        message: '手机号码长度不正确'
      })
      return false
    }
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      Dialog.alert({
        title: '温馨提示',
        message: '手机号码格式不正确'
      })
      return false
    }
    return true
  }

  /*
   * 获取验证码倒计时
   * 2020年4月21日
   * 周祥
   */
  TimeReduce (): void {
    // 如果倒计时没有完毕 则继续执行
    if (this.codeTime) {
      // 倒计时递减
      this.codeTime--
      // 延迟一秒执行
      setTimeout(() => {
        this.TimeReduce()
      }, 1000)
    }
  }

  /*
   * 获取手机号验证码
   * 2020年4月21日
   * 周祥
   */
  GetCode (): void {
    // 是否正在获取验证码，由于网络过慢，防重复点击
    if (this.codeloading) {
      return
    }
    // 是否倒计时完毕
    if (this.codeTime) {
      return
    }
    // 验证手机号
    if (!this.checkPhone(this.search.phone)) {
      return
    }
    this.codeloading = true
    // 调用短信接口 ajax
    api.GETCODE({ phone: this.search.phone }).then(() => {
      this.codeloading = false
      // 60秒倒计时
      this.codeTime = 60
      // 启用倒计时
      setTimeout(() => {
        this.TimeReduce()
      }, 1000)
    })
  }

  onConfirm (value: string, index: any) {
    this.search.org = value
    this.showPicker = false
  }

  save () {
    // if (!this.search.org) {
    //   Dialog.alert({
    //     title: '温馨提示',
    //     message: '请选择出函机构'
    //   })
    //   return
    // }
    if (!this.search.guara_num) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入保函编号'
      })
      return
    }
    if (!this.search.ben) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入投标人或受益人'
      })
      return
    }
    if (!this.search.phone) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入手机号码'
      })
      return
    }
    if (!this.search.code) {
      Dialog.alert({
        title: '温馨提示',
        message: '请输入验证码'
      })
      return
    }
    const toast = Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0
    })
    api.VERIFY({
      phone: this.search.phone,
      guara_num: this.search.guara_num,
      ben: this.search.ben,
      code: this.search.code
    }).then(data => {
      this.baohan = data
      this.showBH = true
    }).finally(() => {
      toast.clear()
    })
  }
}
