import axios from 'axios'
import { Notify, Dialog } from 'vant'
const http = axios.create({
  baseURL: '/api'
})
// 增加头部信息
http.interceptors.request.use(
  (config: any) => {
    // 检查是否有凭据
    config.headers['Content-Type'] = 'application/json'
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)
http.interceptors.response.use(
  (response: any) => {
    const dataAxios = response.data
    const { status } = dataAxios
    if (status === 1) {
      return Promise.resolve(dataAxios.data)
    } else {
      if (response.config.data && JSON.parse(response.config.data).tip) {
        return Promise.reject(dataAxios)
      }
      Dialog.alert({
        message: dataAxios.data ? (typeof dataAxios.data === 'string' ? dataAxios.data : dataAxios.msg) : dataAxios.msg
      })
      return Promise.reject(dataAxios)
    }
  },
  (error: any) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = '请求地址出错'; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    Notify({ type: 'warning', message: error.message })
    return Promise.reject(error)
  }
)
export default http
