import http from '@/utils/http'
class Api {
  /*
  * 获取验证码
  */
  GETCODE = (data: any) => {
    return http.post('/letter/send-sms', data)
  }

  /*
* 获取验证码
*/
  VERIFY = (data: any) => {
    return http.post('/letter/verify', data)
  }
}
export default new Api()
